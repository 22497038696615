.search-query-field {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border: 2px solid #f20057;
  outline: none;
  border-radius: 8px;
  padding: 5px;
  padding-left: 15px;
  width: 250px;
  height: 30px;
  margin-top: 5px;
  position: relative;
}

.search-query-field::placeholder {
  color: #f20057;
}

.search-results {
  background: rgba(0, 0, 0, 0.8);
  width: 265px;
  border-radius: 5px;
  border: 2px solid #f20057;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  position: absolute;
  margin-left: 2px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 1;
}

.search-results ul {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.search-results ul li {
  margin: 8px;
  padding-top: 4px;
  padding-bottom: 8px;
  border-bottom: 1px solid #333;
}

.search-results ul li:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.search-results a {
  text-decoration: none;
  color: white;
}

.search-results a:hover {
  text-decoration: underline;
  font-weight: 600;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 690px) {
  .search-query-field {
    height: 30px;
    width: 90%;

    
  }

  .search-results {
    width: 80%;
  }
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
}

@media only screen and (max-height: 800px) {
}
