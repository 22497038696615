.video-card {
  position: relative;
  width: 300px;
  height: 170px;
  background: rgba(0, 0, 0, 0);
}

.video-card.animate {
  transition: all 0.3s;
}

.video-card-title {
  position: absolute;
  top: 40%;
  left: 50%;
  max-width: 290px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  white-space: initial;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.video-card-title.visible {
  color: #fff;
  opacity: 1;
  visibility: visible;
}

.video-card-image {
  width: 300px;
  height: 170px;
  object-fit: cover;
  vertical-align: 0px;
  transition: all 0.3s;
}

.video-card-image.animate {
  transition: all 0.3s;
}

.video-card.hover.animate {
  width: 330px;
  height: 190px;
}

.video-card.hover.animate .video-card-image {
  width: 330px;
  height: 190px;
  vertical-align: -12px;
  opacity: 0.6;
  border: 3px solid #ffffff;
}

@media only screen and (max-width: 900px) {
  .video-card-title {
    max-width: 220px;
    color: #fff;
    opacity: 1;
    visibility: visible;
  }

  .video-card,
  .video-card-image {
    width: 230px;
    height: 140px;
  }

  .video-card.hover.animate,
  .video-card.hover.animate .video-card-image {
    width: 260px;
    height: 160px;
  }
}

@media only screen and (max-width: 690px) {
  .video-card-title {
    max-width: 180px;
    font-size: 14px;
    color: #fff;
    opacity: 1;
    visibility: visible;
  }

  .video-card,
  .video-card-image {
    width: 205px;
    height: 107px;
  }

  .video-card.hover.animate,
  .video-card.hover.animate .video-card-image {
    width: 220px;
    height: 120px;
  }
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
  .video-card-title {
    max-width: 150px;
    color: #fff;
    opacity: 1;
    visibility: visible;
  }

  .video-card,
  .video-card-image {
    width: 160px;
    height: 90px;
  }

  .video-card.hover.animate .video-card.hover.animate .video-card-image {
    width: 190px;
    height: 110px;
  }
}

@media only screen and (max-height: 800px) {
}
