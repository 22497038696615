.navbar {
  background: rgb(122,32,115);
  background: linear-gradient(90deg, rgba(122,32,115,1) 0%, rgba(32,20,71,1) 48%, rgba(7,7,25,1) 100%);
  height: 80px;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 15px;
  border-bottom: 2px solid #f2005924;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar-logo {
  float: left;
  font-size: 30px;
}

.navbar-logo a {
  color: #f20057;
  text-decoration: none;
}

.navbar-logo a:hover {
  color: #f20057;
}

.navbar-logo-image {
  height: 45px;
}

.navbar-search {
  float: right;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 690px) {
  .navbar {
    height: 130px;
  }

  .navbar-logo,
  .navbar-search {
    float: none;
  }

  .navbar-logo {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
}

@media only screen and (max-height: 800px) {
}
