.video-spotlight-background {
  height: 450px;
  width: 90%;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  position:relative;
  display: block;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border: 2px solid #313131fe;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.video-spotlight-info {
  position: absolute;
  top: 10%;
  left: 6%;
  right: 6%;
  width: 30%;
  height: 80%;
  text-shadow: 2px 2px 5px #000000a2;
}

.video-spotlight-title {
  margin: 0;
  margin-bottom: 30px;
}

.video-spotlight-info button {
  background: #0000006e;
  color: white;
  height: 50px;
  width: 200px;
  position: absolute;
  bottom: 6%;
  font-size: 20px;
  outline: none;
  border: 1px solid #f20057;
  border-radius: 8px;
  font-weight: lighter;
}

.video-spotlight-info button:hover {
  background: #f20057;
}

.video-spotlight-selector {
  position: absolute;
  top: 90%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  cursor:pointer;
}

.video-spotlight-selector div {
  width: 30px;
  height: 10px;
  background: transparent;
  border: 1px solid white;
  border-radius: 2px;
  display: inline-block;
  margin: 20px;
}

.video-spotlight-selector div.active {
  background: #ffffff88;
}

@media only screen and (max-width: 900px) {
  .video-spotlight-background {
    height: 350px;
  }

  .video-spotlight-info-summary {
    display: none;
  }

  .video-spotlight-info {
    text-align: center;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .video-spotlight-info button {
    position: relative;
    margin-top: 10%;
  }

  .video-spotlight-selector div {
    margin: 10px;
  }
}

@media only screen and (max-width: 690px) {
  .video-spotlight-background {
    height: 300px;
    background-size: cover;
  }

  .video-spotlight-info button {
    margin: 0;
  }
}

@media only screen and (max-width: 450px) {
  .video-spotlight-background {
    height: 250px;
    background-size: cover;
  }
}

@media only screen and (max-width: 350px) {
  .video-spotlight-info {
    text-align: center;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .video-spotlight-info button {
    height: 40px;
    width: 100px;
    font-size: 90%;
    margin-top: 10px;
  }
}

@media only screen and (max-height: 800px) {
}
