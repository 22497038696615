.footer {
  height: 80px;
  margin-top: 50px;
  padding: 20px 20px 20px;
  background: black;
  color: white;
  border-top: 1px solid #f2005970;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.footer-soumettre {
  height: 25px;
}

.footer-logo-fecane {
  height: 40px;
}

.footer-logo-canada {
  height: 25px;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 690px) {
}

@media only screen and (max-width: 450px) {
  .footer-soumettre {
    height: 15px;
  }
  
  .footer-logo-fecane {
    height: 25px;
  }
  
  .footer-logo-canada {
    height: 15px;
  }
}

@media only screen and (max-width: 350px) {
  .footer-soumettre {
    height: 15px;
  }
  
  .footer-logo-fecane {
    height: 20px;
  }
  
  .footer-logo-canada {
    height: 12px;
  }
} 
@media only screen and (max-height: 800px) {
}
