.video-tiles {
  width: 88%;
  padding-left: 6%;
  padding-right: 6%;
}

.video-tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-evenly;
  gap: 20px;
  flex-direction: row;
}

.video-tiles-item {
  flex-grow: 1 auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.video-tiles-item-image {
  width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.video-tiles-item-container {
  width: 100%;
  aspect-ratio: 100 / 53;
  background-size: cover;
}

.video-tiles-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 100 / 53;
  opacity: 0;
  background-color: black;
  transition: all 0.2s ease;
}

.video-tiles-item.hover::before {
  opacity: 0.5;
}

.video-tiles-item-title-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.video-tiles-item-title-text {
  padding: 30px;
  font-size: 20px;
  z-index: 1;
  text-align: center;
  visibility: hidden;
}

.video-tiles-item-title-text.visible {
  opacity: 1;
  visibility: visible;
}

.video-tiles-item-title-link {
  color: #ffffff;
  text-decoration: none;
}

img.video-card-image {
border-radius: 8px;
overflow: hidden;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (max-width: 690px) {
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
}

@media only screen and (max-height: 800px) {
}
