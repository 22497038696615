body,
html {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background: rgb(44,41,78);
  background: linear-gradient(180deg, rgb(49, 46, 87) 0%, rgb(0, 0, 0) 100%);
  color: rgba(0, 0, 0, 0);
  font-family: barlow;
  overflow: auto;
}


.App {
  margin: 0 auto;
}

.category-page h1 {
  text-align: center;
  color: #f20057;
}

@import url('https://fonts.cdnfonts.com/css/barlow');

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 690px) {
  .search-input {
    height: 30px;
    width: 95%;
  }

  .search-values {
    width: 87%;
  }
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 350px) {
}

@media only screen and (max-height: 800px) {
}
